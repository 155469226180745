@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");

* {
  font-family: "Open Sans", sans-serif;
  scrollbar-color: #ccc !important;
  scrollbar-width: thin !important;
}

body {
  scroll-behavior: smooth;
}

.faq-item {
  font-family: "Open Sans", sans-serif;
}

.slider-control-bottomcenter .paging-item {
  margin: 4px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #cccccc;
}

::-webkit-scrollbar-thumb:hover {
  background: #afafaf;
}

::-webkit-scrollbar-thumb:active {
  background: #6b6b6b;
}

::-webkit-scrollbar-track {
  background: rgba(204, 204, 204, 0.3);
}

:root {
  --swiper-theme-color: #1182a2 !important;
}
